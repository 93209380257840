<template>
  <table-view ref="tableView"
              url="/site/listForPage"
              :filterable="false"
              :filter-form="dataForm"
              :batch-removable="isAuthed('site_record_delete')"
              delete-url="/site/deleteById"
              :exportable="isAuthed('site_record_export')"
              export-url="/site/download"
              export-file-name="工地信息表"
              :recovered="isAuthed('site_record_paused')"
              recovered-url="/site/recover"
              :paused="isAuthed('site_record_paused')"
              paused-url="/site/pause"
              :addable="isAuthed('site_record_add')"
              :editable="isAuthed('site_record_update')"
              :viewable="isAuthed('site_record_view')"
              :headers="headers"
              show-index
              selectable
              single>
    <el-form slot="search"
             @keyup.enter.native="query">
      <el-input clearable
                v-model="dataForm.siteName"
                placeholder="工地名称"></el-input>
      <el-select v-model="dataForm.baseId"
                 clearable
                 filterable
                 placeholder="所属基地">
        <el-option v-for="item in baseList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <el-select v-model="dataForm.status"
                 clearable
                 filterable
                 placeholder="工地状态">
        <el-option v-for="item in statusList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <!-- <el-select filterable v-model="value1"  collapse-tags multiple placeholder="危险源">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.dictName"
          :value="item.dictId">
        </el-option>
      </el-select> -->
      <el-button @click="query"
                 type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'dispatch-site',

  components: { TableView },

  data() {
    return {
      headers: [
        {
          id: 1,
          prop: 'siteName',
          label: '工地名称',
          minWidth: 250,
          sortable: 'custom',
        },
        {
          id: 2,
          prop: 'status',
          label: '状态',
          width: 100,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return <el-tag type="success">正常</el-tag>
              case 6:
                return <el-tag type="danger">停用</el-tag>
              default:
                return ''
            }
          },
        },
        {
          id: 3,
          prop: 'surveyStatus',
          label: '勘察状态',
          width: 100,
          sortable: 'custom',
          formatter: (row, column, cellValue) => {
            // '勘察状态：1未勘察 2已勘察
            switch (cellValue) {
              case 1:
                return '未勘察'
              case 2:
                return '已勘察'
              default:
                return '未知'
            }
          },
        },
        { id: 4, prop: 'constructionUnit', label: '施工单位', width: 100 },
        {
          id: 5,
          prop: 'mixingTable',
          label: '拌台',
          width: 80,
          sortable: 'custom',
        },
        {
          id: 6,
          prop: 'baseName',
          label: '所属基地',
          width: 100,
          sortable: 'custom',
        },
        { id: 7, prop: 'siteAddress', label: '工地地址', width: 150 },
        // { id: 7, prop: 'scenePhone', label: '工地现场电话', width: 100 },
        {
          id: 8,
          prop: 'headUserName',
          label: '工地负责人',
          width: 120,
          sortable: 'custom',
        },
        {
          id: 9,
          prop: 'updateTime',
          label: '最近勘察时间',
          width: 150,
          sortable: 'custom',
        },
        {
          id: 10,
          prop: 'dangerNames',
          label: '危险源',
          width: 180,
          sortable: 'custom',
        },
      ],
      dataForm: {
        siteName: '',
        baseId: '',
        status: 1,
      },
      baseList: [],
      statusList: [
        { id: 1, name: '正常' },
        { id: 6, name: '停用' },
      ],
      options: [
        {
          dictId: 1,
          dictName: '高压线',
        },
        {
          dictId: 2,
          dictName: '无围护桩',
        },
        {
          dictId: 3,
          dictName: '深坑施工',
        },
        {
          dictId: 4,
          dictName: '顶板支撑',
        },
        {
          dictId: 5,
          dictName: '放坡支撑',
        },
        {
          dictId: 6,
          dictName: '交叉作业',
        },
        {
          dictId: 7,
          dictName: '00线支撑',
        },
        {
          dictId: 8,
          dictName: '其他',
        },
      ],
      value1: [],
    }
  },

  methods: {
    query() {
      this.$refs.tableView.queryData()
    },
  },

  watch: {
    dataForm: {
      handler(v) {
        sessionStorage.setItem('dispatch-site-dataForm', JSON.stringify(v))
      },
      deep: true,
    },
  },
  async created() {
    let _dataForm = sessionStorage.getItem('dispatch-site-dataForm')
    if (_dataForm) {
      this.dataForm = Object.assign(this.dataForm, JSON.parse(_dataForm))
    }
    const data = await this.$http({
      url: this.$http.adornUrl('/user/base/list'),
      method: 'post',
    })
    this.baseList = data.datas
  },
}
</script>
